html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  line-height: normal;
  font-family: 'Gilroy', sans-serif !important;
  text-rendering: optimizeLegibility;
  font-weight: 400;
  color: #343E48;
  box-sizing: border-box;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* > * {
  box-sizing: inherit;
}

body {
  max-width: 100vw;
  overflow-x: hidden;

  /* total width */
  ::-webkit-scrollbar {
    background-color: #F2F4F7;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1rem;
    @media screen and (max-width: 899px) {
      width: 0;
    }
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color: #F2F4F7;
    border-radius: 1rem;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: #CAD2DA;
    border-radius: 1rem;
    @media screen and (max-width: 899px) {
      width: 0;
    }
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
}

input, button {
  font-family: inherit;
}
