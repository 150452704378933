.gas-station {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  .container {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
  }

  &__header {
    padding: 1.5rem 0 0.25rem;
    height: 5.5rem;
  }

  &__body {
    // height: calc(100% - 5.5rem);
    height: 100%;
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .chip-link {
    padding: 0.25rem 0.5rem;
    display: inline-flex;
    align-items: center;

    text-decoration: none;
    background: #F2F4F7;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 100%;
    color: #72879E;
    border-radius: 1rem;
    outline: none;
    border: none;
    font-family: inherit;
    overflow: visible;

    .mat-icon, svg {
      width: 1.25rem;
      height: 1.25rem;
      font-size: 1.25rem;
      line-height: 1.25rem;
      margin-right: 0.25rem;
    }

    svg {
      stroke: currentColor;
    }
  }

  .button-flat {
    background: #39434D;
    text-decoration: none;
    color: white;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
    width: 100%;
    display: inline-block;
    border-radius: 2.75rem;
    border: none;

    &:disabled, &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  .divider {
    display: block;
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 100%;
    background: #343E48;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .button-back {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    color: #343E48;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.375rem;
    text-decoration: none;

    .mat-icon {
      flex: 0 0 auto;
      width: 1.25rem;
      height: 1.25rem;
      font-size: 1.25rem;
      line-height: 1.25rem;
      color: #72879E;
      margin-right: 1rem;
    }
  }

  @import "content-centered";
  @import "screen";
  @import "screen-section";
  @import "station-info";
  @import "demo";
}
