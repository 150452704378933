.rounded-images-slider {
  swiper-container {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  swiper-slide {
    width: 19.5rem;
    height: auto;
    margin-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    &:last-child {
      margin-right: 0;
    }

    img {
      width: 100%;
      display: block;
      height: 100%;
      border-radius: 1.25rem;
      object-fit: cover;
      object-position: center;
      box-shadow: -1px 3px 16px 1px #212C3726;
    }
  }
}
