.screen-section {
  padding: 1rem 0;
  border-bottom: 1px solid #EBEBEB;

  &:last-child, &._wo-border {
    border-bottom: none;
  }

  &__title {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
  }

  &__subtitle {
    margin-top: 0.25rem;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    color: #979797;
  }

  &__notice {
    margin-top: 0.25rem;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
  }
}
