@font-face {
  font-family: Gilroy;
  font-weight: 400;
  font-style: normal;
  src: url("/assets/fonts/Gilroy/Gilroy-Regular.eot");
  src: url("/assets/fonts/Gilroy/Gilroy-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Gilroy/Gilroy-Regular.woff") format("woff"), url("/assets/fonts/Gilroy/Gilroy-Regular.ttf") format("truetype")
}

@font-face {
  font-family: Gilroy;
  font-weight: 300;
  font-style: normal;
  src: url("/assets/fonts/Gilroy/Gilroy-Light.eot");
  src: url("/assets/fonts/Gilroy/Gilroy-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Gilroy/Gilroy-Light.woff") format("woff"), url("/assets/fonts/Gilroy/Gilroy-Light.ttf") format("truetype")
}

@font-face {
  font-family: Gilroy;
  font-weight: 500;
  font-style: normal;
  src: url("/assets/fonts/Gilroy/Gilroy-Medium.eot");
  src: url("/assets/fonts/Gilroy/Gilroy-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Gilroy/Gilroy-Medium.woff") format("woff"), url("/assets/fonts/Gilroy/Gilroy-Medium.ttf") format("truetype")
}

@font-face {
  font-family: Gilroy;
  font-weight: 700;
  font-style: normal;
  src: url("/assets/fonts/Gilroy/Gilroy-Bold.eot");
  src: url("/assets/fonts/Gilroy/Gilroy-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Gilroy/Gilroy-Bold.woff") format("woff"), url("/assets/fonts/Gilroy/Gilroy-Bold.ttf") format("truetype")
}

@font-face {
  font-family: Gilroy;
  font-weight: 400;
  font-style: normal;
  src: url("/assets/fonts/Gilroy/Gilroy-Regular.eot");
  src: url("/assets/fonts/Gilroy/Gilroy-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Gilroy/Gilroy-Regular.woff") format("woff"), url("/assets/fonts/Gilroy/Gilroy-Regular.ttf") format("truetype")
}

@font-face {
  font-family: Gilroy;
  font-weight: 500;
  font-style: normal;
  src: url("/assets/fonts/Gilroy/Gilroy-Medium.eot");
  src: url("/assets/fonts/Gilroy/Gilroy-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Gilroy/Gilroy-Medium.woff") format("woff"), url("/assets/fonts/Gilroy/Gilroy-Medium.ttf") format("truetype")
}

@font-face {
  font-family: Gilroy;
  font-weight: 700;
  font-style: normal;
  src: url("/assets/fonts/Gilroy/Gilroy-Bold.eot");
  src: url("/assets/fonts/Gilroy/Gilroy-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Gilroy/Gilroy-Bold.woff") format("woff"), url("/assets/fonts/Gilroy/Gilroy-Bold.ttf") format("truetype")
}

@font-face {
  font-family: 'Nexa';
  src: url('/assets/fonts/Nexa/nexa_bold.eot');
  src: url('/assets/fonts/Nexa/nexa_bold.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Nexa/nexa_bold.woff') format('woff'),
       url('/assets/fonts/Nexa/nexa_bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('/assets/fonts/Nexa/nexa_book-italic.eot');
  src: url('/assets/fonts/Nexa/nexa_book-italic.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Nexa/nexa_book-italic.woff2') format('woff2'),
       url('/assets/fonts/Nexa/nexa_book-italic.woff') format('woff'),
       url('/assets/fonts/Nexa/nexa_book-italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Nexa';
  src: url('/assets/fonts/Nexa/nexa_book.eot');
  src: url('/assets/fonts/Nexa/nexa_book.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Nexa/nexa_book.woff2') format('woff2'),
       url('/assets/fonts/Nexa/nexa_book.woff') format('woff'),
       url('/assets/fonts/Nexa/nexa_book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('/assets/fonts/Nexa/nexa_light.eot');
  src: url('/assets/fonts/Nexa/nexa_light.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Nexa/nexa_light.woff2') format('woff2'),
       url('/assets/fonts/Nexa/nexa_light.woff') format('woff'),
       url('/assets/fonts/Nexa/nexa_light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('/assets/fonts/Nexa/nexa_regular.eot');
  src: url('/assets/fonts/Nexa/nexa_regular.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/Nexa/nexa_regular.woff2') format('woff2'),
       url('/assets/fonts/Nexa/nexa_regular.woff') format('woff'),
       url('/assets/fonts/Nexa/nexa_regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
