.content-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;

  &__icon {
    width: 6rem;
    height: 6rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__title {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    margin-bottom: 1rem;
  }

  &__subtitle {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__description {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
