.demo-color {
  color: #D3B033;
}

.demo-footer {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: #D3B033;
  text-align: center;
}
