/* You can add global styles to this file, and also import other style files */
@import "styles/fonts";

@import "styles/general";

@import "styles/components/slider";
@import "styles/components/dealer-chat";

@import "styles/pages/gas-station";

html,
body {
    height: 100%;
}

// body { margin: 0; font-family: 'Gilroy', sans-serif !important }

tes-fuel-delivery,
tes-fuel-delivery-status,
tes-dealer-map,
tes-choose-center {
    .map-container {

        height: 425px !important;
        width: 100% !important;
        // position: relative;
        // overflow: hidden;
        margin-top: 15px !important;

    }
}

tes-fuel-delivery,
tes-fuel-payment,
tes-pump-payment,
tes-select-car,
tes-choose-center {
    .mdc-form-field {
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
    }
}

tes-fuel-delivery,
tes-pump .mat-mdc-chip-selected {
    background: #72879E !important;
}

tes-fuel-delivery,
tes-pump .mat-mdc-standard-chip {
    background: #D7E4F9 !important;
}

tes-fuel-delivery,
tes-pump .mdc-evolution-chip__text-label {
    color: #ffffff;
}

tes-fuel-delivery,
tes-fuel-payment,
tes-pump-payment,
tes-select-car,
tes-choose-center {
    .mat-mdc-radio-button.mat-accent {
        --mdc-radio-selected-focus-icon-color: #72879E !important;
        --mdc-radio-selected-hover-icon-color: #72879E !important;
        --mdc-radio-selected-icon-color: #72879E !important;
        --mdc-radio-selected-pressed-icon-color: #72879E !important;
        --mat-mdc-radio-checked-ripple-color: #72879E !important;
    }

    .mat-mdc-radio-button {
        font-family: 'Gilroy', sans-serif !important
    }
}

._demo_text {
    color: #D3B033;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    margin: 8px 0;
}


.car_info {
    display: flex;
    align-items: center;

    ._model {
        font-weight: 500;
        font-size: 18px;
        color: #343E48;
    }

    ._number {
        font-weight: 500;
        font-size: 14px;
        color: #343E48;
    }
}

.mat-bottom-sheet-container {
    font-family: 'Gilroy';
    font-style: normal;
    border-radius: 30px 30px 0px 0px !important;
    height: auto;
}

.mat-bottom-sheet-container-small {
    max-height: 50vh !important;
}

.mat-bottom-sheet-container-mid {
    max-height: 70vh !important;
}

.mat-bottom-sheet-container-big {
    min-width: none !important;

    .mat-bottom-sheet-container {
        max-height: 90vh !important;
    }
}

tes-pump .map-container,
tes-pump-status .map-container {
    height: 21.875rem !important;
    width: 100% !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: #f2f4f7 !important;
}

.mat-mdc-snack-bar-container {
    --mat-mdc-snack-bar-button-color: #343e48 !important;
    --mdc-snackbar-container-color: #333333 !important;
    --mdc-snackbar-supporting-text-color: rgb(52 62 72) !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    box-shadow: none !important;
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action {
    font-family: 'Gilroy' !important;
}
.mat-mdc-snack-bar-container .mdc-snackbar__label {
    font-family: 'Gilroy' !important;
}
