.gas-station-screen {
  padding-top: 0.75rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__express_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;

    margin-top: 10px;
    width: 100%;
    height: 38px;

    background: #F2F4F7;
    border: 2px solid #F2F4F7;
    border-radius: 15px;

    ._price {
      font-size: 16px;
      color: #343E48;
      font-weight: 600;
    }
  }

  &__body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    // padding-bottom: 5.25rem;
  }

  &__title {
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;
    letter-spacing: 0.01em;
    color: #343E48;
  }

  &__footer {
    // position: fixed;
    // bottom: 0;
    // left: 0;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    background: white;
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .change-params {
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 130%;
      letter-spacing: 0.01em;
      text-decoration-line: underline;
      color: #72879E;
    }

    &__brand {
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.375rem;
      text-align: center;
      letter-spacing: 0.01em;
      color: #343E48;
    }

    &__telematic_label {
      font-weight: 400;
      margin: 5px 0;
      font-size: 0.75rem;
      line-height: 130%;
      letter-spacing: 0.01em;
      color: #979797;
      display: flex;
      align-items: center;
    }

    &__telematic_image {
      width: 2.5rem;
      height: 3.5rem;
      margin-right: 0.375rem;
    }
  }

  .link-footer {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-decoration: none;
    color: inherit;
  }

  .link-footer-wrapper {
    width: 100%;
    text-align: center;
  }
}