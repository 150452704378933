.station-info {
  padding-bottom: 0.75rem;
  display: flex;
  align-items: center;

  &__icon {
    flex: 0 0 auto;
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    overflow: hidden;
    margin-right: 0.75rem;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__text {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  &__subtitle {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
    color: #424E58;
  }

  &__subtitle {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
  }

  &__price {
    flex: 0 0 auto;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
}
